import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { compose, withProps, lifecycle, withState } from "recompose";
import { findIndex, map, compact, isEmpty, find, get } from "lodash";

import Column from "./wallView/Column";
import HeaderButtons from "./wallView/HeaderButtons";
import AppStage from "./wallView/AppStage";
import UnassignedItems from "./wallView/UnassignedItems";
import {
  fetchApp,
  updateItem,
  fetchItems,
  loadMoreItemsByColumn,
  fetchItems as fetchItemsForTag,
  fetchItems as fetchItemsForMember,
} from "actions/appCreator";
import {
  itemsSelector as itemsForWallSelector,
  loadMoreUrlsSelector,
  totalArchivedItemsSelector,
} from "selectors/appCreator";
import store from "store";
import wrapProvider from "helpers/components/wrapProvider";
import useFullWidthLayout from "hooks/useFullWidthLayout";

function ItemsWallView({
  items,
  app,
  columnField,
  cardTitleField,
  cardDescriptionField,
  cardDateField,
  cardLogoField,
  cardTagField,
  columns,
  onMoveCard,
  cardFileField,
  cardMembershipField,
  layoutOptions,
  loadMoreUrls,
  loadMoreItems,
  unassignedItems,
  setCardHeight,
  cardHeight,
  isArchive,
  appUrl,
  totalArchivedItems,
  setFilteredByTag,
  setFilteredByMember,
  showsFilteredItems,
}) {
  useFullWidthLayout();

  return (
    <div className="column-container">
      <div className="column-container-header">
        {isArchive || showsFilteredItems ? (
          <HeaderButtons url={appUrl} type={isArchive ? "archive" : "filter"} />
        ) : null}
        {layoutOptions.stage ? (
          <AppStage
            logoId={app.logo ? app.logo.id : ""}
            description={app.description}
          />
        ) : null}
        {!isEmpty(unassignedItems) ? (
          <UnassignedItems
            cardProps={{
              isArchive,
              onMoveCard,
              setCardHeight,
              setFilteredByTag,
              setFilteredByMember,
            }}
            titlePropName={cardTitleField.name}
            items={unassignedItems}
            canUpdateColumn={get(columnField, "can.update")}
          />
        ) : null}
      </div>
      {isEmpty(columns) ? (
        <div className="alert alert-danger">
          {I18n.t("js.apps.wall.no_columns.hint")}
        </div>
      ) : (
        <div className="overflow-x-auto">
          <div className={`columns flex gap-2 mt-6 w-min`}>
            {map(columns, (column, index) => {
              return (
                <Column
                  key={index}
                  isLast={columns.length - 1 === index}
                  cardTitleField={cardTitleField}
                  cardDescriptionField={cardDescriptionField}
                  cardDateField={cardDateField}
                  cardLogoField={cardLogoField}
                  columnField={columnField}
                  onMoveCard={onMoveCard}
                  cardFileField={cardFileField}
                  cardMembershipField={cardMembershipField}
                  cardTagField={cardTagField}
                  setCardHeight={setCardHeight}
                  cardHeight={cardHeight}
                  appId={app.id}
                  appUrl={appUrl}
                  isArchive={isArchive}
                  setFilteredByTag={setFilteredByTag}
                  setFilteredByMember={setFilteredByMember}
                  canUpdateColumn={get(app, "properties")}
                  hasMoreItems={!isEmpty(loadMoreUrls[column.value])}
                  loadMoreItems={() =>
                    loadMoreItems({ url: loadMoreUrls[column.value] })
                  }
                  totalArchivedItems={
                    totalArchivedItems ? totalArchivedItems[column.value] : "0"
                  }
                  cards={compact(
                    map(items, (item) =>
                      get(item, ["values", columnField.name, 0]) ===
                      column.value
                        ? item
                        : null,
                    ),
                  )}
                  {...column}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

ItemsWallView.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  unassignedItems: PropTypes.arrayOf(PropTypes.shape({})),
  columnField: PropTypes.shape({
    name: PropTypes.string,
  }),
  cardTitleField: PropTypes.shape({
    name: PropTypes.string,
  }),
  cardDescriptionField: PropTypes.shape({
    name: PropTypes.string,
  }),
  cardDateField: PropTypes.shape({
    format: PropTypes.string,
    label: PropTypes.string,
  }),
  cardLogoField: PropTypes.shape({
    url: PropTypes.string,
  }),
  cardFileField: PropTypes.shape({
    name: PropTypes.string,
  }),
  cardMembershipField: PropTypes.shape({
    name: PropTypes.string,
  }),
  cardTagField: PropTypes.shape({
    name: PropTypes.string,
  }),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  onMoveCard: PropTypes.func,
  layoutOptions: PropTypes.shape({
    stage: PropTypes.bool,
  }),
  parseMarkdown: PropTypes.func,
  loadMoreItems: PropTypes.func,
  showUnassignedItems: PropTypes.bool,
  showsFilteredItems: PropTypes.bool,
  toggleShowUnassignedItems: PropTypes.func,
  setCardHeight: PropTypes.func,
  setFilteredByTag: PropTypes.func,
  setFilteredByMember: PropTypes.func,
  resetTagFilter: PropTypes.func,
  filteredByTag: PropTypes.string,
  cardHeight: PropTypes.number,
  app: PropTypes.shape({}),
  isArchive: PropTypes.bool,
  loadMoreUrls: PropTypes.objectOf(PropTypes.string),
  appUrl: PropTypes.string,
  totalArchivedItems: PropTypes.shape({}),
};

export default compose(
  wrapProvider(store),
  connect(
    (state) => ({
      items: itemsForWallSelector(state),
      loadMoreUrls: loadMoreUrlsSelector(state),
      totalArchivedItems: totalArchivedItemsSelector(state),
    }),
    (dispatch, { app, isArchive }) => ({
      onMoveCard: ({ cardId, fieldName, targetColumn, insertAfterCardId }) => {
        dispatch(
          updateItem({
            appId: app.id,
            itemId: cardId,
            insertAfterCardId,
            body: {
              insert_after_id: insertAfterCardId,
              values: { [fieldName]: [targetColumn] },
            },
            optimistic: true,
          }),
        );
      },
      initApp: () => {
        dispatch({ type: fetchApp.SUCCESS, payload: app });
      },
      fetchItems: () => {
        if (isArchive) {
          dispatch(fetchItems({ appId: app.id, view: "wall", archived: true }));
        } else {
          dispatch(fetchItems({ appId: app.id, view: "wall" }));
        }
      },
      fetchItemsForTag: ({ tag }) => {
        if (isArchive) {
          dispatch(
            fetchItemsForTag({
              appId: app.id,
              view: "wall",
              archived: true,
              tag,
            }),
          );
        } else {
          dispatch(fetchItemsForTag({ appId: app.id, view: "wall", tag }));
        }
      },
      fetchItemsForMember: ({ assignee_id }) => {
        if (isArchive) {
          dispatch(
            fetchItemsForMember({
              appId: app.id,
              view: "wall",
              archived: true,
              assignee_id,
            }),
          );
        } else {
          dispatch(
            fetchItemsForMember({ appId: app.id, view: "wall", assignee_id }),
          );
        }
      },
      loadMoreItems: ({ url }) => {
        dispatch(loadMoreItemsByColumn({ url }));
      },
    }),
  ),
  withState("showsFilteredItems", "setShowsFilteredItems", false),
  lifecycle({
    componentDidMount() {
      this.props.initApp();
      if (this.props.tag_filter) {
        this.props.fetchItemsForTag({ tag: this.props.tag_filter });
        this.props.setShowsFilteredItems(true);
        Application.Pagetitle.set_title(
          I18n.t("js.apps.app.tag_filter.title", {
            app_name: this.props.app.name,
            tag_filter: this.props.tag_filter,
          }),
        );
      } else if (this.props.member_filter) {
        this.props.fetchItemsForMember({
          assignee_id: this.props.member_filter,
        });
        this.props.setShowsFilteredItems(true);
        Application.Pagetitle.set_title(
          I18n.t("js.apps.app.member_filter.title", {
            app_name: this.props.app.name,
            member_name: this.props.member_name,
          }),
        );
      } else {
        this.props.fetchItems();
      }
    },
  }),
  withProps(({ app, items }) => {
    const layoutOptions = get(app, [
      "collection_layout",
      "options",
      get(app, ["collection_layout", "layout"]),
    ]);

    //@get('collection_layout').options?[@get('collection_layout').layout] || {}
    const getField = ({ selector = "type", value }) =>
      app.properties[
        findIndex(app.properties, (p) => get(p, selector) === value)
      ];

    const cardDescriptionField =
      app.properties[
        findIndex(
          app.properties,
          (p) =>
            p.type === "text" && p.name !== getField({ value: "text" }).name,
        )
      ];

    const appColumns = get(
      find(
        app.properties,
        (prop) => prop._id === layoutOptions.column_property,
      ),
      "options",
    );

    const unassignedItems = compact(
      map(items, (item) =>
        !get(item, ["values", layoutOptions.column_property, 0]) ? item : null,
      ),
    );

    const props = {
      columnField: getField({
        selector: "name",
        value: layoutOptions.column_property,
      }),
      cardTitleField: getField({ value: "text" }),
      cardDescriptionField: cardDescriptionField,
      cardDateField: getField({ value: "date" }),
      cardLogoField: getField({ value: "image" }),
      cardFileField: getField({ value: "file" }),
      cardMembershipField: getField({ value: "membership" }),
      cardTagField: getField({ value: "tag" }),
      columns: appColumns,
      layoutOptions,
      unassignedItems,
    };
    return { ...props };
  }),
  withState("cardHeight", "setCardHeight", 0),
  DragDropContext(HTML5Backend),
)(ItemsWallView);
