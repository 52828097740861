import React, { RefObject } from "react";
import AllDayTag from "components/appointments/appointmentsList/AllDayTag";
import { Appointment, Calendar } from "../../../@types/appointments";
import moment, { Moment } from "moment";
import { spansDate, spansMultipleDays } from "components/appointments/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import classNames from "classnames";
import { find, isEmpty, map } from "lodash";

type AppointmentItemArgs = {
  appointment: Appointment;
  date: Moment;
  calendars?: Calendar[];
};

function TimeTag({ date, appointment }: AppointmentItemArgs) {
  if (!spansMultipleDays(appointment.date)) {
    return (
      <div className="flex flex-row justify-center items-center">
        {appointment.date.all_day ? (
          <div className="flex flex-row text-sm">
            {I18n.t("js.calendars.date_range.all_day")}
          </div>
        ) : (
          <div className="flex flex-row text-sm">
            {moment(appointment.date.start).format("HH:mm")}
            {!isEmpty(appointment.date.end) && (
              <div className="text-sm">
                {" - "}
                {moment(appointment.date.end).format("HH:mm")}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  if (spansDate(date, appointment.date)) {
    return (
      <div className="flex flex-row">
        <AllDayTag.LeftArrow />
        <AllDayTag />
        <AllDayTag.RightArrow />
      </div>
    );
  } else if (date.isSame(moment(appointment.date.start), "day")) {
    return appointment.date.all_day ? (
      <div className="flex flex-row">
        <AllDayTag position="start" />
        <AllDayTag.RightArrow />
      </div>
    ) : (
      <>
        <div className="text-sm pr-1">
          {moment(appointment.date.start).format("HH:mm")}
        </div>
        <div className="text-sm">
          <FontAwesomeIcon icon={regular("angle-right")} />
        </div>
      </>
    );
  } else {
    return appointment.date.all_day ? (
      <div className="flex flex-row">
        <AllDayTag.LeftArrow />
        <AllDayTag position="end" />
      </div>
    ) : (
      <>
        <div className="text-sm">
          <FontAwesomeIcon icon={regular("angle-left")} />
        </div>
        <div className="text-sm pl-1">
          {moment(appointment.date.end).format("HH:mm")}
        </div>
      </>
    );
  }
}

export default function AppointmentItem({
  appointment,
  date,
  calendars,
  todayRef,
}: AppointmentItemArgs & { todayRef: RefObject<HTMLDivElement> }) {
  return (
    <div
      className="grid gap-2 grid-cols-[1.2fr,2fr] md:grid-cols-[0.5fr,2fr] mb-2 last:mb-0"
      ref={
        moment().isSame(moment(appointment.date.start), "day") ? todayRef : null
      }
    >
      <div className="flex flex-row justify-center items-center">
        <TimeTag date={date} appointment={appointment} />
      </div>
      <div className="name flex flex-row">
        {map(appointment.calendar_ids, (calendar_id: string) => {
          const calendar = find(
            calendars,
            (calendar) => calendar.id === calendar_id,
          );

          return (
            calendar && (
              <div
                key={calendar_id}
                className="calendar h-full w-1"
                style={{ backgroundColor: `#${calendar?.color}` }}
              ></div>
            )
          );
        })}
        <span
          className={classNames("ml-1", {
            "line-through": appointment.status === "canceled",
          })}
        >
          {appointment.has_meeting && (
            <FontAwesomeIcon icon={regular("comments")} />
          )}
          <a href={appointment.html_url}>{appointment.name}</a>
          <span className="text-sm text-gray-600 ml-1">
            {appointment.location.name}
          </span>
        </span>
      </div>
    </div>
  );
}
